import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import store from '@redux/store';
import {
  ExperienceConfiguration,
  NinetailedProvider
} from '@ninetailed/experience.js-next';
import { NinetailedPreviewPlugin } from '@ninetailed/experience.js-plugin-preview';
import NinetailedSegmentPlugin from '@ninetailed/experience.js-plugin-segment';
import ErrorBoundary from '@components/errorBoundary';
import '../styles/globals.scss';

if (typeof window !== 'undefined' && process.env.environment == 'dev') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

export default function App({ Component, pageProps }) {
  return (
    <NinetailedProvider
      plugins={[]}
      clientId={'d2e98c47-2910-4a94-a7ef-005283f996c6'}
      environment={process.env.NEXT_PUBLIC_NINETAILED_ENVIRONMENT ?? 'main'}
      componentViewTrackingThreshold={0} // Default = 2000
      useSDKEvaluation={true}
    >
      <ErrorBoundary>
        <ReduxProvider store={store}>
          <Component {...pageProps} />
        </ReduxProvider>
      </ErrorBoundary>
    </NinetailedProvider>
  );
}
